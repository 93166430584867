<template lang="html">
  <div class="VideoChatPage" :class="{'full-size':isFullSize}">
    <CommunityHeader v-if="!chatVideoCreated" :title="$filters.translate($filters.formatActivityType(communityCurrentActivity.type)).toUpperCase()" :shadowText="$filters.translate('activity').toUpperCase()"/>
    <div class="VideoChatPage__WaitingRoom" v-if="!chatVideoCreated">
      <div class="VideoChatPage--Actions" v-if="isClient">
        <CommunityButton v-if="isModerator" v-on:click="openUrlsModal()">
          <i class="fas fa-share-alt"></i> Urls de acceso
        </CommunityButton>
        <CommunityButton>
          <a href="https://experience-labs-files-dev.s3.amazonaws.com/01/165/1592210801551Manual_Moderador.pdf" target="_blank"><img src="@/assets/img/document.svg"/>{{$t('videochat_moderator_manual')}}</a>
        </CommunityButton>
      </div>
      <div class="VideoChatPage__WaitingRoom__Video">
        <h1>{{$t('videochat_access')}}</h1>
        <h2 v-if="$route.name == 'videochat-public'" v-html="communityCurrentActivity.description"></h2>
        <div class="VideoChatPage__WaitingRoom__Video__Test" v-if="!isObservator">
          <p class="VideoChatPage__WaitingRoom__Video__Test--text">{{$t('videochat_test')}}</p>
          <div>
            <p><img v-if="navigatorMedia.video" src="@/assets/img/active.svg"><img v-else src="@/assets/img/no-active.svg"> {{$t('welcome_video')}}</p>
            <p><img v-if="navigatorMedia.audio" src="@/assets/img/active.svg"><img v-else src="@/assets/img/no-active.svg"> {{$t('videochat_audio')}}</p>
          </div>
        </div>
        <div class="VideoChatPage__WaitingRoom__Video--User">
          <video :id="'userVideo-'+communityCurrentActivityId" autoplay poster="@/assets/img/defaultuser.png">
          </video>
        </div>

        <div class="VideoChatPage__WaitingRoom__Video__Error" v-if="!isObservator">
          <div class="VideoChatPage__WaitingRoom__Video__Error__Card" v-if="!navigatorMedia.audio">
            <img src="@/assets/img/no-active-audio.svg">
            <div class="VideoChatPage__WaitingRoom__Video__Error--Text">
              <h1>{{$t('videochat_audio')}}</h1>
              <p>{{$t('videochat_disabled_micro')}} <a :href="getManualForBrowser()" target="_blank">{{$t('videochat_disabled_info')}}</a></p>
            </div>
          </div>
          <div class="VideoChatPage__WaitingRoom__Video__Error__Card" v-if="!navigatorMedia.video">
            <img src="@/assets/img/no-active-audio.svg">
            <div class="VideoChatPage__WaitingRoom__Video__Error--Text">
              <h1>{{$t('welcome_video')}}</h1>
              <p>{{$t('videochat_disabled_video')}} <a :href="getManualForBrowser()" target="_blank">{{$t('videochat_disabled_info')}}</a></p>
            </div>
          </div>
        </div>
        <div class="VideoChatPage__WaitingRoom__Video__Ready" v-if="navigatorMedia.audio && navigatorMedia.video && !videochatAccessEnabled && isUser">
          <h1>{{$t('videochat_ready')}}</h1>
          <p>{{$t('videochat_ready_to_go')}}</p>
        </div>
        <div class="VideoChatPage__WaitingRoom__Video__Start" v-if="navigatorMedia.audio && navigatorMedia.video && isUser">
          <button type="button" :disabled="!videochatAccessEnabled" name="button" v-on:click="waitForModeratorAllowAccess()">{{$t('videochat_start')}}</button>
        </div>
        <div class="VideoChatPage__WaitingRoom__Video__Start" v-if="isClient">
          <button type="button" name="button" v-if="isObservator && videochatAccessEnabled" v-on:click="createVideoChat()">{{$t('videochat_start')}}</button>
          <button type="button" name="button" v-if="navigatorMedia.audio && navigatorMedia.video && isModerator" v-on:click="createVideoChat()">{{$t('videochat_start_moderator')}}</button>
          <div class="VideoChatPage__WaitingRoom__Video__UserList">
            <div class="VideoChatPage__WaitingRoom__Video__UserList--User" v-for="(tr,i) in mediaTestResults" v-show="(tr.online && communityCurrentActivity.publicActivity) || !communityCurrentActivity.publicActivity">
              <p>{{tr.testerName}}</p>
              <div class="VideoChatPage__WaitingRoom__Video__UserList--icons" v-if="!tr.audioWorking || !tr.videoWorking">
                <div :class="{'VideochatUserOnline':tr.online}">

                </div>
                <img v-if="!tr.audioWorking" src="@/assets/img/audio-icon.svg">
                <img v-if="tr.audioWorking" src="@/assets/img/audio-icon-green.svg">
                <img v-if="!tr.videoWorking" src="@/assets/img/video-icon.svg">
                <img v-if="tr.videoWorking" src="@/assets/img/video-icon-green.svg">
              </div>
              <div v-else class="VideoChatPage__WaitingRoom__Video__UserList--icons">
                <div :class="{'VideochatUserOnline':tr.online}">

                </div>
                <img src="@/assets/img/active-bg.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="VideoChatPage--ActivityChats">
        <div class="VideoChatPage--ChatTabs" v-if="isClient">
          <div :class="{'active': currentTab == 'ACTIVITY'}" v-on:click="currentTab = 'ACTIVITY';videochatAdjustScroll('ACTIVITY')">
            {{$t('videochat_chat_activity')}}
          </div>
          <div :data-content="observerNewMessage" :class="{'active': currentTab == 'OBSERVER', 'msgObserverCount':observerNewMessage > 0}" v-on:click="currentTab = 'OBSERVER';observerNewMessage=0;videochatAdjustScroll('OBSERVER')">
            {{$t('videochat_observator_chat')}}
          </div>
          <div :class="{'active': currentTab == 'USERS'}" v-on:click="currentTab = 'USERS'" v-show="!isObservator">
            {{$t('videochat_permission')}}
          </div>
        </div>
        <Chat ref="chatObserver" :title="$t('videochat_observator_chat')" v-on:send-message="sendMessage($event,'OBSERVER')" :messages="communityChatMessagesObs" v-if="isClient  && currentTab == 'OBSERVER'"/>
        <Chat ref="chatActivity" :members="activityMembers" :disabled="isObservator" :title="$t('videochat_chat_activity')" v-on:send-message="sendMessage($event,'ACTIVITY')" :messages="chatMessages" v-if="currentTab == 'ACTIVITY'"/>
        <div class="VideoChatPage--UserAccessList" v-if="currentTab == 'USERS'">
          <div class="VideoChatPage--UserAccessListItem" v-for="(tr,i) in mediaTestResults" v-show="(tr.online && communityCurrentActivity.publicActivity) || !communityCurrentActivity.publicActivity">
            <SwitchInput v-model="tr.accessGranted" :text="(tr.accessGranted ? $t('deny') : $t('enable')) + ' ' + $t('access_to') +' <b>'+ tr.testerName+'</b>'" v-on:input="grantAccessToVideoChat(tr)"/>
          </div>
        </div>
      </div>
    </div>
    <Videochat
      ref="Videochat"
      v-if="chatVideoCreated"
      v-on:hide-header="$emit('hide-header',$event);isFullSize=$event"
      v-on:observer-message-send="sendMessage($event,'OBSERVER')"
      v-on:activity-message-send="sendMessage($event,'ACTIVITY')"
      v-on:exit="exitVideoChat('CHAT_ENABLED')"
      v-on:grant-access="grantAccessToVideoChat($event)"
      v-on:user-removed="userRemovedFromVideochat($event)"
      v-on:observer-chat-opened="observerNewMessage = 0"
      v-on:share-links="openUrlsModal()"
      :activityChatMessages="chatMessages"
      :accessUsers="mediaTestResults"
      :activityMembers="activityMembers"
      :badgePermissions="countUsersPermissionPending"
      :badgeChatObserver="observerNewMessage"/>
    <div class="VideoChatPage--ShareUrlsWrapper" v-if="isOpenUrlModal">
      <div class="VideoChatPage--ShareUrls">
        <div class="VideoChatPage--ShareUrlsHeader">
          <h4>{{$t('videochat_access_url_title')}}</h4>
          <i class="fas fa-times" v-on:click="openUrlsModal()"></i>
        </div>
        <div class="VideoChatPage--ShareUrlsContainer">
          <div v-on:click="copyVideochatUrl()"><b>{{$t('videochat_access_users')}}: </b> <i class="far fa-copy"></i> {{$t('videochat_copy_url')}}</div>
          <div v-on:click="copyVideochatUrlObs()"><b>{{$t('videochat_access_observers')}}: </b> <i class="far fa-copy"></i> {{$t('videochat_copy_url')}}</div>
        </div>
        <!--<div class="VideoChatPage--ShareUrlsContainer" v-for="obd of communityCurrentActivity.observerDatas">
          <div v-on:click="copyVideochatUrl(obd.authToken)"><b>{{obd.name}}: </b> <i class="far fa-copy"></i>{{$t('videochat_copy_url2')}}</div>
          <div v-on:click="copyVideochatUrl(obd.authToken,true)"><b>{{obd.name}}: </b> <i class="far fa-copy"></i>{{$t('videochat_copy_url3')}}</div>
        </div>-->
        <div class="copy-url-message">
          {{$t('videochat_url_copied')}}
          <i class="far fa-check-circle" style="color:rgb(150, 200, 0)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations  } from 'vuex';
import moment from 'moment-timezone';
import Pusher from 'pusher-js';
import store from '@/store';
import {
  COMMUNITY_FETCH_VIDEO_CHAT,
  COMMUNITY_SET_VIDEO_AUDIO_TEST,
  COMMUNITY_FETCH_VIDEO_AUDIO_TEST,
  COMMUNITY_PUT_VIDEO_CHAT_MESSAGE,
  COMMUNITY_GET_VIDEO_CHAT_MESSAGE,
  COMMUNITY_PUT_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS,
  COMMUNITY_GET_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS,
  COMMUNITY_GRANT_ACCESS_TO_VIDEOCHAT,
 } from '@/store/community/chat.module';
 import { COMMUNITY_FETCH_MEMBERS } from "@/store/community/members.module";
 import {COMMUNITY_FETCH_ACTIVITY } from '@/store/community/activities.module'
 import CommunityHeader from '@/components/CommunityHeader.vue'
 import Chat from '~/components/WatFocus/Chat.vue'
 import CommunityButton from '@/components/CommunityButton.vue'
 import SwitchInput from '~/components/SwitchInput/SwitchInput'
 import Videochat from '@/components/Videochat/Videochat.vue'
 import {copyTextToClipboard} from '@/utils'
 import Badge from '~/components/Badge';
 import { NOTIFICATIONS_FETCH_STATE } from '@/store/notifications/notifications.module';
 import { RELOAD_NOTIF_INTERVAL } from '~/constants/constants.type';

export default {
  name: 'VideoChatPage',
  components:{
    CommunityHeader,
    Chat,
    CommunityButton,
    SwitchInput,
    Badge,
    Videochat
  },
  computed: {
    ...mapGetters([
      'theme',
      'communityInfo',
      'communityCurrentActivity',
      'communityCurrentActivityId',
      'communityMembers',
      'communityChatMessagesObs',
      'isClient',
      'isUser',
      'isObservator',
      'isModerator',
      'onlineUsers',
    ]),
    countUsersPermissionPending(){
      let count = 0
      for(let i in this.mediaTestResults){
        if(this.mediaTestResults[i].online && this.mediaTestResults[i].audioWorking && this.mediaTestResults[i].videoWorking && !this.mediaTestResults[i].accessGranted) count++
      }
      return count
    },
    timeOutTime(){
      return this.chatVideoCreated ? 20000 : 6000
    }
  },
  data(){
    return{
      activityId:0,
      chatMessages:[],
      currentTab:'ACTIVITY',
      chatConnected:false,
      chatPusher:null,
      mediaTestResults:[],
      navigatorMedia:{
        video:false,
        videoUrl:{},
        audio:false,
      },
      videochatNotReadyForObserver:true,
      videochatAccessEnabled:false,
      chatVideoCreated:false,
      videochatLocalStream:null,
      videochatLocalAudio:null,
      isChatCollapsed:false,
      videochatChatUnseen:0,
      isOpenUrlModal:false,
      observerNewMessage:0,
      isFullSize:false,
      activityMembers:[]
    }
  },
  watch: {
    communityCurrentActivityId: async function(val) {
      if(isNaN(this.$route.params.id)) return
      await this.exitWaitingRoom()
      await this.exitVideoChat()
      await this.restoreDefaults()
      await this.init()
    },
  },
  methods:{
    restoreDefaults(){
      //this.activityId=0
      this.chatMessages=[]
      this.currentTab='ACTIVITY'
      this.chatConnected=false
      this.chatPusher=null
      this.mediaTestResults=[]
      this.navigatorMedia={
        video:false,
        videoUrl:{},
        audio:false
      }
      this.videochatNotReadyForObserver=true
      this.videochatAccessEnabled=false
      this.chatVideoCreated=false
      this.videochatLocalStream=null
      this.videochatLocalAudio=null
      this.isChatCollapsed=false
      this.videochatChatUnseen=0
      this.isOpenUrlModal=false
      this.observerNewMessage=0
    },
    ...mapMutations({
      addChatMessageObs: 'addChatMessageObs',
    }),
    async grantAccessToVideoChat(testMedia){
      await store.dispatch(COMMUNITY_GRANT_ACCESS_TO_VIDEOCHAT, {activityId:this.communityCurrentActivityId, testerId:testMedia.identifier, grantAccess:{grantAccess:testMedia.accessGranted}})
    },
    async userRemovedFromVideochat(event){
      let testResult = _.filter(this.mediaTestResults, function (m,i) {
        m.accessGranted = false
        return event == i
      })[0]
      this.grantAccessToVideoChat(testResult)
    },
    exitWaitingRoom(){
      if(this.videochatLocalAudio != null){
        this.videochatLocalAudio.getAudioTracks()[0].stop()
      }
      if(this.videochatLocalStream != null){
        this.videochatLocalStream.getVideoTracks()[0].stop()
      }
      if(this.chatPusher != null){
        this.chatPusher.disconnect()
        this.chatPusher = null
      }
    },
    async exitVideoChat(enabled){
      this.isChatCollapsed = false
      $('.VideoChat__InternalChat').removeClass('hide')
      if(this.videochatLocalAudio != null){
        this.videochatLocalAudio.getAudioTracks()[0].stop()
      }
      if(this.videochatLocalStream != null){
        this.videochatLocalStream.getVideoTracks()[0].stop()
        // stream.getTracks().map(function (val) {
        //   val.stop();
        // });
        // this.videochatLocalStream.stop()
      }
      if(this.chatPusher != null && enabled != "CHAT_ENABLED"){
        this.chatPusher.disconnect()
        this.chatPusher = null
      }
      this.chatVideoCreated = false
      this.isFullSize = false
      this.$emit('hide-header', false)
      this.$emit('hide-sidebar', false)
    },
    async createVideoChat(){
      this.chatVideoCreated = true
      this.$emit('hide-sidebar', true)
    },

    async userAllowAccess() {
    try {
      const data = await store.dispatch(COMMUNITY_FETCH_VIDEO_CHAT, { isObservator: this.isObservator });
      if (data.data != null) {
        if (data.status === 400) {
          if (this.isObservator && !this.$route.query.waitingRoom && !this.videochatAccessEnabled) {
            this.createVideoChat();
            this.videochatAccessEnabled = true;
            const Toast = Swal.mixin({
              toast: true,
              position: "center",
              showConfirmButton: false,
              timer: 6000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            Toast.fire({
              icon: "info",
              text:this.$t('msg_videochat_waiting'),
              customClass:{popup:"swal-customInfo"},
            });
          }
          if (this.$route.name === "activities.videochat" || this.$route.name === "videochat-public" || this.$route.name === 'videochat-public-observer') {
            setTimeout(() => { this.userAllowAccess()}, 5000);
          }
        }
      } else {
        this.videochatAccessEnabled = true;
        this.videochatNotReadyForObserver = false;
        if (this.isObservator && !this.$route.query.waitingRoom && this.chatVideoCreated) {
          Swal.close();
          this.$refs.Videochat.getVideoChatInfo();
        } else {
          Swal.fire({
            text:this.$t('videochat_start_msg'),
            icon:"info",
            customClass:{popup:"swal-customInfo"}
          })
        }
      }
    } catch (error) {
      console.error("Error during user access:", error);
    }
  },

    async waitForModeratorAllowAccess() {
      let continuePolling = true;
      while (continuePolling) {
        try {
          const data = await store.dispatch(COMMUNITY_FETCH_VIDEO_CHAT, { isObservator: this.isObservator });
          if (data.accessGranted) {
            Swal.close();
            this.createVideoChat();
            continuePolling = false; 
          } else {
            Swal.fire({
              text: this.$t('msg_videochat_waiting_permission'),
              icon: "info",
              customClass: { popup: "swal-customInfo" },
              allowOutsideClick: false,
              showCancelButton: false,
              showConfirmButton: false
            });
          }
        } catch (error) {
          console.error("Error en pollWaitForModeratorAllowAccess:", error);
        }
        if (continuePolling) {
          await new Promise(resolve => setTimeout(resolve, this.timeOutTime));
        }
      }
    },
    async testUserMedia() {
    if (navigator.userAgent.indexOf("MiuiBrowser") > -1) {
      Swal.fire({
        title: this.$t('msg_videochat_navigator_not_working_title'),
        text: this.$t('msg_videochat_navigator_not_working_text'),
        icon: "info",
        customClass: { popup: "swal-customInfo" },
      });
      return;
    }

    if (!this.isObservator) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.navigatorMedia.audio = true;
        this.videochatLocalAudio = audioStream;

        const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.navigatorMedia.video = true;
        document.querySelector('#userVideo-' + this.communityCurrentActivityId).srcObject = videoStream;
        this.videochatLocalStream = videoStream;

      } catch (error) {
        console.error("Error accessing media devices:", error);
        this.navigatorMedia.audio = false;
        this.navigatorMedia.video = false;
      }
    }

    if (!this.isModerator) {
      await this.userAllowAccess();
    }

    if (this.isUser) {
      await store.dispatch(COMMUNITY_SET_VIDEO_AUDIO_TEST, {
        videoWorking: this.navigatorMedia.video,
        audioWorking: this.navigatorMedia.audio
      });
    }
  },
    async fetchTestsUserMedia() {
      let continuePolling = true;
      while (continuePolling) {
        try {
          const data = await store.dispatch(COMMUNITY_FETCH_VIDEO_AUDIO_TEST);
          for (let i in data.testResults) {
            data.testResults[i].online = false;
          }
          this.mediaTestResults = data.testResults;

          for (let id of this.onlineUsers.testersOnlineCondensed) {
            let videochatUser = _.find(this.communityMembers, m => m.identifier == id);
            if (videochatUser !== undefined) {
              if (this.mediaTestResults[videochatUser.nickname] !== undefined) {
                this.mediaTestResults[videochatUser.nickname].online = true;
                this.mediaTestResults[videochatUser.nickname].identifier = id;
              }
            } else {
              await this.fetchCommunityMembers();
            }
          }

          if (!(this.communityCurrentActivity.type === 'VIDEOCHAT' && this.communityCurrentActivity.status === 'ACTIVE')) {
            continuePolling = false;
          }
        } catch (error) {
          console.error("Error en pollFetchTestsUserMedia:", error);
        }
        if (continuePolling) {
          await new Promise(resolve => setTimeout(resolve, this.timeOutTime));
        }
      }
    },

    async fetchCommunityMembers(){
      await store.dispatch( COMMUNITY_FETCH_MEMBERS,{order: "recent", notSetCommunityMembers: false })
    },
    async fetchChatMessages(){
      await store.dispatch(COMMUNITY_GET_VIDEO_CHAT_MESSAGE,{id:this.activityId})
      .then((data) => {
        this.chatMessages = data.messages
      });
      if(this.isClient){
        await store.dispatch(COMMUNITY_GET_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS,{id:this.activityId})
      }
    },
    async sendMessage(event,chat){
      if(chat == "OBSERVER")await store.dispatch(COMMUNITY_PUT_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS,event)
      else await store.dispatch(COMMUNITY_PUT_VIDEO_CHAT_MESSAGE,event)
    },
    async fetchActivity(){
      await store.dispatch(COMMUNITY_FETCH_ACTIVITY, {activityId:this.activityId})
      .then((data) => {
        if(data.status == 'FINISHED') this.$router.push({ name: 'activity.info', params: { id:data.identifier} })
        this.fetchChatMessages()
      })
    },
    subscribeChat(){
      if(this.chatConnected) return
      this.chatConnected = true
      Pusher.logToConsole = false;
      this.chatPusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: 'eu',
        forceTLS: true,
      });
      this.chatPusher.subscribe('chat-' + this.theme.communityId + '-activity-' + this.communityCurrentActivityId)
      this.chatPusher.subscribe('chat-' + this.theme.communityId + '-activity-' + this.communityCurrentActivityId + '-observer')
      this.chatPusher.bind('chat-message', this.handleReceivedMessageVideochat)
    },
    handleReceivedMessageVideochat(message){
      if(message.chatName == 'chat-' + this.theme.communityId + '-activity-' + this.communityCurrentActivityId + '-observer'){
        message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
        message.creationDate += '.307+0000'
        this.addChatMessageObs(message)
        this.videochatAdjustScroll('OBSERVER')
        if(!message.isModerator && this.isModerator && this.currentTab != 'OBSERVER'){
          this.observerNewMessage++
        }
      }
      else {
        if(this.$refs.Videochat) this.$refs.Videochat.newMessageChat(message)
        message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
        message.creationDate += '.307+0000'
        this.chatMessages.push(message)
        this.videochatAdjustScroll('ACTIVITY')
      }
      if(this.isChatCollapsed) this.videochatChatUnseen++
    },
    videochatAdjustScroll(chat){
      if(chat == 'OBSERVER'){
        if(this.$refs.chatObserver != undefined)this.$refs.chatObserver.adjustMessagesScroll()
      }
      else if(chat == 'ACTIVITY'){
        if(this.$refs.chatActivity != undefined)this.$refs.chatActivity.adjustMessagesScroll()
      }
      else{
        if(this.$refs.chatObserver != undefined)this.$refs.chatObserver.adjustMessagesScroll()
        if(this.$refs.chatActivity != undefined)this.$refs.chatActivity.adjustMessagesScroll()
      }
    },
    getManualForBrowser(){
      let isFirefox = typeof InstallTrigger !== 'undefined';
      let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      let isIE = /*@cc_on!@*/false || !!document.documentMode;
      let isEdge = !isIE && !!window.StyleMedia;
      let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      if (isFirefox) return "https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro"
      if (isSafari) return "https://support.apple.com/es-es/guide/safari/ibrw7f78f7fe/mac"
      if (isEdge) return "https://support.microsoft.com/es-es/help/4468232/windows-10-camera-microphone-and-privacy"
      if (isChrome) return "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=es-419"
    },
    openUrlsModal(){
      this.isOpenUrlModal = !this.isOpenUrlModal
    },
    getVideoChatUrl(authToken, accesToWaitingRoom){
      let url = location.origin+'/c/'+this.communityInfo.urlToken+'/activities/'+this.communityCurrentActivity.videochatPublicHash+'/video-chat-public'
      if(authToken != undefined) url += "?auth="+authToken
      if(accesToWaitingRoom) url += "&waitingRoom=true"
      return url
    },
    getVideoChatUrlObs(){
      let url =
        location.origin +
        "/c/" +
        this.communityInfo.urlToken +
        "/activities/" +
        this.communityCurrentActivity.videochatPublicHash +
        "/video-chat-public-observer";
      return url;
    },
    copyVideochatUrl(authToken,accesToWaitingRoom){
      copyTextToClipboard(this.getVideoChatUrl(authToken, accesToWaitingRoom))
      $(".copy-url-message").show()
      setTimeout(() => {
        $(".copy-url-message").hide()
      }, 2000)
    },
    copyVideochatUrlObs() {
      copyTextToClipboard(this.getVideoChatUrlObs());
      $(".copy-url-message").show()
      setTimeout(() => {
        $(".copy-url-message").hide()
      }, 2000)
    },
    async init(){
      this.activityMembers = this.communityMembers
      this.activityId = isNaN(this.$route.params.id) ? this.$route.params.id.split("-")[this.$route.params.id.split("-").length-1] : this.$route.params.id
      await this.fetchActivity()
      await this.testUserMedia()
      await this.subscribeChat()
      if(this.isClient) await this.fetchTestsUserMedia()
      if(this.communityCurrentActivity.publicActivity && this.isUser){
        setInterval( (  ) => {
          store.dispatch(NOTIFICATIONS_FETCH_STATE, { isClient: store.getters.isClient, hideSpinner: false} );
        }, RELOAD_NOTIF_INTERVAL );
      }
      
    }
  },
  async mounted(){
    if(this.isClient){
      await this.fetchCommunityMembers()
    }
    await this.init()
    this.$router.beforeEach((to, from) => {
        Swal.close()
    })
  },
  beforeRouteLeave (to, from, next) {
      this.exitWaitingRoom()
      if(this.$refs.Videochat != undefined) this.$refs.Videochat.exit()
      next();
  },
}
</script>
<style lang="scss">

.VideoChatPage{
  .SwitchInput{
    display: grid;
    grid-template-columns: 43px auto;
    &--input{
      height: 22px!important;
      width: 43px!important;
      div{
        width: 14px!important;
        height: 14px!important;
      }
      &.active div{
        margin-left: calc(100% - 19px)!important;
      }
    }
    p{
      font-size: 15px!important;
    }
  }
}
</style>
<style scoped lang="scss">
.VideoChatPage{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 70px);
  overflow-y: auto;
  &.full-size{
    height: 100vh;
  }
  .CommunityHeader{
    margin-bottom: 0px;
    padding-top: 49px;
    padding-bottom: 49px;
    z-index: 0;
  }
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  &--UserAccessList{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--UserAccessListItem{
    background: #F5F5F5;
    padding: 10px;
    font-size: 18px;
    color: #3E3E3E;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
  }
  &__WaitingRoom{
    width: 90%;
    z-index:1;
    display: flex;
    background: #FFFFFF;
    box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal!important;
    margin-bottom:50px;
    position: relative;
    margin-top: -59px;
    @media screen and ( max-width: 768px ) {
      flex-direction: column;
    }
    &__Video{
      padding:20px;
      width: 50%;
      @media screen and ( max-width: 768px ) {
        width: 100%;
      }
      h1{
        color: #3E3E3E;
        font-size: 32px;
        font-weight: normal;
      }
      h2{
        font-size: 18px;
        color: #808080;
        font-weight: normal;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      &__Test{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--text{
          font-size: 15px;
          color: #747474;
        }
        div{
          font-size: 19px;
          color: #3E3E3E;
          display: flex;
          text-transform: capitalize;
          p{
            margin-left: 20px;
            display: flex;
            align-items: center;
            img{
              margin-right:5px;
            }
          }
        }
      }
      &--User{
        box-sizing: border-box;
        max-height: 268px;
        margin: 10px 0;
        margin: 10px auto;
        max-width: 456px;
        video source, video{
          border: 1px solid #E1E1E1;
          object-fit: cover;
          max-height: 268px;
        }
        video[poster]{
          width:100%;
        }
      }
      &__Error{
        &__Card{
          background: #FFFFFF;
          border: 1px solid #E1E1E1;
          box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.08);
          display: flex;
          align-items: flex-start;
          padding: 20px;
          margin:10px 0;
          img{
            margin-right: 10px;
            margin-top: 4px;
          }
          h1{
            font-weight: bold;
            text-transform: capitalize;
            font-size: 22px;
          }
          p{
            font-size: 15px;
            color: #747474;
          }
        }
      }
      &__Ready{
        margin-top: 20px;
        h1{
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          color: #96C800;
          text-transform: uppercase;
        }
        p{
          font-size: 18px;
          text-align: center;
          color: #3E3E3E;
        }
      }
      &__Start{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 5px;
        button{
          background: #96C800;
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
          border-radius: 5px;
          color: #FFFFFF;
          border:none;
          text-transform: uppercase;
          font-family: Roboto Condensed;
          font-weight: bold;
          font-size: 19px;
          text-align: center;
          padding: 8px 14px;
          cursor: pointer;
          outline: none;
          &:disabled{
            opacity:0.5;
            cursor: not-allowed;
          }
        }
      }
      &__UserList{
        display: grid;
        grid-template-columns: auto auto;
        width: 100%;
        grid-gap: 5px;
        margin-top: 50px;
        @media screen and ( max-width: 1000px ) {
          grid-template-columns: auto;
        }
        &--User{
          background: #F5F5F5;
          padding: 10px;
          font-size: 18px;
          color: #3E3E3E;
          display: flex;
          justify-content: space-between;
          img{
            margin-left: 5px;
          }
        }
        &--icons{
          display: flex;
          flex-wrap: nowrap;
          flex-grow: 1;
          align-self: center;
          justify-content: flex-end;
          div{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: lightgrey;
            margin-top: 2.5px;
            &.VideochatUserOnline{
              background: #a1de5f;
            }
          }
        }
      }
    }
    &__Chat{
      width: 50%;
      background: #F5F5F5;
      border-left: 1px solid #D3D3D3;
      border-right: 1px solid #D3D3D3;
      height: auto;
      display: flex;
      flex-direction: column;
      @media screen and ( max-width: 768px ) {
        width: 100%;
        z-index: 100;
      }

      &--chatObs{
        background: #EBEBEB;
        border-bottom: 1px solid #D3D3D3;
      }
      &--chat, &--chatObs{
        padding: 15px;
        height:100%;
        h1{
          font-size: 22px;
          text-align: center;
          color: #3E3E3E;
          font-weight: normal;
          margin-bottom: 10px;
        }
      }
      &__Messages{
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        height: 700px;
        overflow: auto;
        @media (max-width: 768px) {
          max-height: 400px;
        }
        &::-webkit-scrollbar-track{
          background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
          background-color: #8134ff;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 10px;
          background: var(--primary-color);
        }
        .chatMessage{
          width:100%;
          display: flex;
          justify-content: flex-end;
          min-height: 64px;
          &__Media{
            width: 100%;
            i{
              margin-right: 5px;
            }
            a{
              color:#3e3e3e;
            }
            img,video{
              width: 100%;
              border: 1px solid #D3D3D3;
              cursor: pointer;
            }
          }
          &__Message{
            margin:10px;
            margin-left: 15px;
            font-size: 17px;
            color: #3E3E3E;
            max-width: 65%;
            padding: 10px;
            position:relative;
            p:first-child{
              font-size: 13px;
              color: #3E3E3E;
              opacity: 0.5;
              text-align: right;
            }
          }
          &--mod{
            justify-content: flex-start;
            .chatMessage__Message{
              background: rgba(188, 220, 112,0.5);
              &:after {
                content: "";
                position: absolute;
                top: 17px;
                left: -16px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent rgba(188, 220, 112,0.5) transparent transparent;
              }
              p:first-child{
                text-align: left;
              }
            }
          }
          &--user{
            justify-content: flex-start;
            .chatMessage__Message{
              background:  #F7F4F8;
              &:after {
                content: "";
                position: absolute;
                top: 17px;
                left: -16px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent #F7F4F8 transparent transparent;
              }
              p:first-child{
                text-align: left;
              }
            }
          }
        }
      }
      &--input{
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin:10px 0;
        font-family: Roboto Condensed;
        textarea{
          height: 100%;
          border:none;
          width:100%;
          outline: none;
          resize: none;
          padding-top: 10px;
          &::placeholder{
            color: #969696;
            font-size:17px;
            opacity: 0.6;
          }
        }
        button{
          border: none;
          background: transparent;
          outline: none;
        }
        i{
          color: #96C800;
          font-size: 16px;
        }
      }
    }
    &__Tabs{
      display: flex;
      position: absolute;
      margin-top: -34px;
      margin-left:10px;
      font-size: 14px;
      line-height: 12px;
      div{
        cursor: pointer;
        max-width: 86px;
        background: #E1E1E1;
        padding: 5px 10px;
        margin-right: 5px;
        box-shadow: inset 0px -2px 3px rgba(0, 0, 0, 0.04);
        &.active{
          background: #F5F5F5;
          box-shadow: none;
        }
      }
    }
    &__AddMedia {
      display: flex;
      &--icon {
        background-color: #F2F2F2;
        margin: 5px 0;
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #DFDFDF;
        }
      }
    }
  }
  &--ActivityChats{
    width: 50%;
    max-height: 780px;
    background: #F5F5F5;
    border-left: 1px solid #D3D3D3;
    border-right: 1px solid #D3D3D3;
    height: auto;
    display: flex;
    flex-direction: column;
    @media screen and ( max-width: 768px ) {
      width: 100%;
    }
  }
  &--ChatTabs{
    display: flex;
    position: absolute;
    margin-top: -34px;
    margin-left:10px;
    font-size: 14px;
    line-height: 12px;
    .msgObserverCount{
      position:relative;
      &:after{
        content:attr(data-content);
        position: absolute;
        top: -5px;
        right: -6px;
        background-color: var(--primary-color);
        width: 22px;
        height: 22px;
        display: flex;
        border-radius: 50%;
        font-weight: bold;
        color: #fff;
        justify-content: center;
        font-size: 10px;
        flex-direction: column;
        text-align: center;
        border: 2px solid #fff;
      }
    }
    div{
      cursor: pointer;
      max-width: 86px;
      background: #E1E1E1;
      padding: 5px 10px;
      margin-right: 5px;
      box-shadow: inset 0px -2px 3px rgba(0, 0, 0, 0.04);
      &.active{
        background: #F5F5F5;
        box-shadow: none;
      }
    }
  }
  &--Collapse{
    background: #EBEBEB;
    width: 32px;
    height: 28px;
    display: flex;
    align-items:center;
    justify-content: center;
    position: absolute;
    margin-left: -31px;
    margin-top: 15px;
    border: 1px solid #D3D3D3;
    cursor: pointer;
    @media (max-width: 768px) {
      display:none;
    }
    .Badge{
      background: var(--primary-color);
      position:absolute;
      top: -10px;
      left: -10px;
    }
  }
  &--Actions{
    position: absolute;
    top: -67px;
    right: 0;
    display: flex;
    i{
      margin-right: 5px;
    }
    a,button{
      font-size: 14px;
      color: #FFFFFF;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-right: 10px;
    }
  }
  &--ShareUrls{
    min-height: 300px;
    width: 65%;
    max-width: 600px;
    padding: 20px 30px;
    word-break: break-word;
    background: white;
    border: 1px solid #c3c3c3;
    position: relative;
    i{
      color: var(--primary-color);
      cursor: pointer;
      margin-right: 3px;
      margin-left: 5px;
      transition: 0.2s;
      &:hover{
        font-size: 20px;
      }
    }
    h4{
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.01em;
      color: #404040;
      margin: 0 0 9px 0;
      font-size: 21px;
    }
  }
  &--ShareUrlsWrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.35);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--ShareUrlsContainer{
      padding: 5px;
      position: relative;
      margin-top: 5px;
      margin-bottom: 10px;
      display: grid;
      grid-gap: 15px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-shadow: 2px 2px 1px rgb(0 0 0 / 7%);

      div{
        cursor: pointer;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #404040;
      }

      b{
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.01em;
        color: #404040;
        margin: 0 0 10px 0;
      }
  }
  &--ShareUrlsHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .copy-url-message{
    position: absolute;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0 0px 10px lightgrey;
    left: 34%;
    background: white;
    bottom: 50%;
    font-weight: bold;
    display: none;
  }
}
</style>
